.dashboard {
  .card-body {
    .btn {
      margin-bottom: 0px;
    }
  }

  .results {
    button {
      text-transform: uppercase;
    }
  }
  .mobile-app-widget {
    .mobile-app-widget__top-line {
      display: flex;

      .mobile-app-widget__total-stat {
        direction: ltr;
        padding: 0;
        font-size: 28px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: normal;
        margin-right: auto;
        line-height: normal;
      }
    }

    .mobile-app-widget__top-line--pink {
      .mobile-app-widget__total-stat {
        color: #ff4861;
      }
    }

    .mobile-app-widget__top-line--violet {
      .mobile-app-widget__total-stat {
        color: $color-violet;
      }
    }

    .mobile-app-widget__top-line--lime {
      .mobile-app-widget__total-stat {
        color: #b8e986;
      }
    }

    .mobile-app-widget__top-line--blue {
      .mobile-app-widget__total-stat {
        color: #48b5ff;
      }
    }

    .mobile-app-widget__top-line--turquoise {
      .mobile-app-widget__total-stat {
        color: #4ce1b6;
      }
    }

    .mobile-app-widget__title {
      text-transform: uppercase;
      position: relative;
      margin: 0 0 20px;

      h5 {
        font-size: 12px;
        color: #555555;
        font-weight: 500;
      }
    }

    .dashboard__trend-icon {
      margin: 0;
      height: 29px;
      min-width: 28px;
      fill: #d8dfe9;
    }

    .progress__label {
      font-size: 14px;
      font-weight: 500;
    }

    .progress__label {
      right: -7px;
    }

    .progress-bar {
      border-radius: 5px;
    }
  }

  .dashboard__users-stat {
    .dashboard__trend-icon {
      fill: $color-light-gray;
      height: 24px;
      width: 24px;
      min-width: 24px;
      margin-top: auto;
    }
  }

  .dashboard__current-users {
    display: flex;
    align-items: center;
    flex-direction: column;

    .dashboard__current-users-chart {
      position: relative;
      width: 100%;

      div,
      svg {
        width: 100% !important;
        margin: 0 auto;
      }

      .dashboard__current-users-label {
        margin: 0;
        position: absolute;
        top: calc(50% + 10px);
        text-align: center;
        font-size: 38px;
      }
    }

    .dashboard__current-users-info {
      display: flex;
      align-items: flex-start;
      width: 100%;
      justify-content: space-between;
      font-size: 13px;
      line-height: 1.54;
      color: #555555;

      p {
        margin: 0;
      }

      .dashboard__current-users-day {
        display: flex;
        flex-direction: column;
        align-items: center;

        & span:last-child {
          font-size: 16px;
          line-height: 1.25;
          font-weight: 500;
          color: #555555;
        }
      }
    }

    @media screen and (min-width: 1539px) {
      .dashboard__current-users-info {
        padding: 0 20px;
      }
    }

    @media screen and (min-width: 1200px) and (max-width: 1539px) {
      .dashboard__current-users-chart {
        svg,
        div {
          height: 100px !important;
        }
      }
    }

    @media screen and (min-width: 768px) and (max-width: 1023px) {
      .dashboard__current-users-info {
        padding: 0 75px;
      }
    }
  }

  .dashboard__active-users-chart {
    overflow: hidden;
  }

  .dashboard__carousel {
    margin-bottom: -25px;

    .slick-dots {
      bottom: -35px;
    }

    .slick-arrow.slick-prev,
    .slick-arrow.slick-next {
      background: none;
      opacity: 0;
      pointer-events: none;
    }

    .dashboard__carousel-slide {
      border-radius: 5px;
      padding: 15px 13px;
      position: relative;
      overflow: hidden;

      svg {
        position: absolute;
        height: 80px;
        width: 80px;
        top: calc(50% - 40px);
        opacity: 0.3;
      }

      p {
        color: $color-additional;
        margin: 0;
      }

      .dashboard__carousel-title {
        color: $color-red;
        font-size: 24px;
        font-weight: 500;
      }

      @include themify($themes) {
        background: themed("colorBackgroundBody");
      }

      &.dashboard__carousel-slide--red {
        background: $color-red;

        p {
          color: white;
          opacity: 0.45;
        }

        svg {
          fill: #ffffff;
        }

        .dashboard__carousel-title {
          opacity: 1;
        }
      }
    }
  }
}
.suggestion.modal-dialog {
  max-width: 585px !important;
}

.suggestion.modal-dialog {
  .modal-title {
    font-weight: bold;
    font-size: 18px;
  }
  .modal-content {
    padding: 30px 40px 0;
  }
}
