.payment {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid black;
  padding: 8px 0px;
  h5 {
    color: black;
  }

  .subhead {
    font-size: 12px !important;
    line-height: 18px;
  }
}

.StripeElement {
  max-width: 500px;
  padding: 12px 16px !important;
  box-shadow: 2px 2px 5px #ccc !important;
  margin-bottom: 12px !important;
}

.stripe-form label {
  color: gray;
}
